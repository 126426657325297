.header-text {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.header-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 0.5rem;
    margin: 1rem;
    font-size: 1rem;
    font-family: Signika-Light, sans-serif;
    color: var(--text-primary);
    border-bottom: 0.1rem solid var(--text-dark)
}

