.text {
    display: flex;
    font-family: Signika-SemiBold, sans-serif;
    color: var(--text-primary);
    justify-content: center;
    align-items: center;
    margin: 0;
}

.text-container {
    display: flex;
    flex-direction: row;
    text-align: center;
}

.text-light {
    font-family: Signika-Regular, sans-serif;
    color: var(--secondary);
}

.separator {
    font-family: Signika-Light, sans-serif;
    padding-left: 1rem;
    padding-right: 1rem;
    color: var(--quaternary);
}

.loading {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    background: var(--background-gradient);
    width: 100%;
    height: 100vh;
}

.innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

